import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { ToasterEvent } from './toaster.model';
import { ToasterService } from './toaster.service';

@Component({
  selector: 'di-toaster',
  templateUrl: './toaster.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'tw-fixed tw-bottom-0 tw-left-0 tw-z-50 tw-m-4',
  },
})
export class ToasterComponent implements OnInit {
  currentToasts: ToasterEvent[] = [];

  constructor(
    private toasterService: ToasterService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscribeToToasts();
  }

  subscribeToToasts() {
    this.toasterService.toastEvents$.subscribe((toast: ToasterEvent) => {
      toast.message ? this.addToast(toast) : this.dispose(this.getIndexToRemove(toast.id));
      this.cdr.detectChanges();
    });
  }

  private getIndexToRemove(toastId: string): number {
    return this.currentToasts.findIndex((existingToast) => existingToast.id === toastId);
  }

  dispose(index: number) {
    if (index === -1) return;
    this.currentToasts.splice(index, 1);
    this.cdr.detectChanges();
  }

  private addToast(toast: ToasterEvent) {
    const messageExists = this.currentToasts.some((toast) => toast.message === toast.message);

    if (!messageExists) {
      this.currentToasts.push(toast);
    }
  }
}
