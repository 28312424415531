import { WsActionCompleted } from '@bo-schema-ws/server-payloads/ws-action-completed';
import { WsActionCreated } from '@bo-schema-ws/server-payloads/ws-action-created';
import { WsActionDetail } from '@bo-schema-ws/server-payloads/ws-action-detail';
import { WsActionV3Completed } from '@bo-schema-ws/server-payloads/ws-action-v3-completed';
import { WsActionV3Created } from '@bo-schema-ws/server-payloads/ws-action-v3-created';
import { WsCacheListChanged } from '@bo-schema-ws/server-payloads/ws-cache-list-changed';
import { WsClientFailure } from '@bo-schema-ws/server-payloads/ws-client-failure';
import { WsConnectionEstablished } from '@bo-schema-ws/server-payloads/ws-connection-established';
import { WsConnectionExpires } from '@bo-schema-ws/server-payloads/ws-connection-expires';
import { WsOperationCompleted } from '@bo-schema-ws/server-payloads/ws-operation-completed';
import { WsOperationCreated } from '@bo-schema-ws/server-payloads/ws-operation-created';
import { WsPong } from '@bo-schema-ws/server-payloads/ws-pong';
import { WsTaskCompleted } from '@bo-schema-ws/server-payloads/ws-task-completed';
import { WsTaskDetail } from '@bo-schema-ws/server-payloads/ws-task-detail';

// message schema for sending to clients
export interface WsServerMessageDto {
  // generated when the message is created
  messageId: string;
  createdTimeIso: string;

  // AWS lambda invocation ID that created this message
  requestCorrelationId: string;
  // the bulk operation FE session that caused this message, if known
  sessionCorrelationId?: string;
  // the bulk operation FE call that caused this message, if known
  callCorrelationId?: string;
  // message is caused by action taken by this user, if known
  originOdpUserId?: string;

  // message will be sent to clients using this company
  odpOrgId: string;

  payload: WsServerPayloadSchema;
}

export type WsServerPayloadSchema =
  // infrastructure
  | { type: WsServerPayloadEnum.ClientFailure; value: WsClientFailure }
  | { type: WsServerPayloadEnum.ConnectionEstablished; value: WsConnectionEstablished }
  | { type: WsServerPayloadEnum.Pong; value: WsPong }
  | { type: WsServerPayloadEnum.ConnectionExpires; value: WsConnectionExpires }
  // actions
  | { type: WsServerPayloadEnum.ActionCreated; value: WsActionCreated }
  | { type: WsServerPayloadEnum.ActionDetail; value: WsActionDetail }
  | { type: WsServerPayloadEnum.ActionCompleted; value: WsActionCompleted }
  // actionsV3 for employee card edit
  | { type: WsServerPayloadEnum.ActionV3Created; value: WsActionV3Created }
  | { type: WsServerPayloadEnum.ActionV3Completed; value: WsActionV3Completed }
  // operations and tasks
  | { type: WsServerPayloadEnum.OperationCreated; value: WsOperationCreated }
  | { type: WsServerPayloadEnum.OperationCompleted; value: WsOperationCompleted }
  | { type: WsServerPayloadEnum.TaskDetail; value: WsTaskDetail }
  | { type: WsServerPayloadEnum.TaskCompleted; value: WsTaskCompleted }
  // entity cache
  | { type: WsServerPayloadEnum.CacheListChanged; value: WsCacheListChanged };

export enum WsServerPayloadEnum {
  // Client protocol failure detected. Most likely a bugfix is needed.
  ClientFailure = 'clientFailure',
  // Sent when connection is established, but it's NOT guaranteed to be the first message.
  ConnectionEstablished = 'connectionEstablished',
  // Sent in reply to ping.
  Pong = 'pong',
  // Sent in reply to ping when AWS would close the connection (2 hours) before the next ping.
  // The client must make a new connection before this is close, to avoid losing messages.
  ConnectionExpires = 'connectionExpires',

  // Sent when an action has been requested for the current organization.
  ActionCreated = 'actionCreated',
  // Debug information about an action (eg. handler started work, polling started, temporary error)
  ActionDetail = 'actionDetail',
  // Sent when an action has been completed (succeeded, failed or timed out).
  ActionCompleted = 'actionCompleted',

  // Sent when an employee card edit action has been requested for the current organization.
  ActionV3Created = 'actionV3Created',
  // Sent when an action has been completed (succeeded, failed or timed out).
  ActionV3Completed = 'actionV3Completed',

  // Sent when an operation has been requested for the current organization.
  OperationCreated = 'operationCreated',
  // Sent when an operation has been completed (succeeded, failed or timed out).
  OperationCompleted = 'operationCompleted',
  // Debug information about a task (eg. handler started work, polling started, temporary error)
  TaskDetail = 'taskDetail',
  // Sent when a task has been completed (succeeded, failed or timed out).
  TaskCompleted = 'taskCompleted',

  // Sent when the a list changed in the cache, ie.
  // when the original data changed or an action is started.
  CacheListChanged = 'cacheListChanged',
}
