import { Component } from '@angular/core';
import { TaskStatusEnum } from '@bo-schema-api-dto';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'di-grid-status-col-cell-renderer',
  templateUrl: './grid-status-col-cell-renderer.component.html',
  styleUrls: ['./grid-status-col-cell-renderer.component.scss'],
})
export class GridStatusColCellRendererComponent implements ICellRendererAngularComp {
  isSuccessful: boolean;
  isFailed: boolean;
  isPending: boolean;
  agInit(params: ICellRendererParams<TaskStatusEnum>): void {
    this.setCellStatus(params.value);
  }

  refresh(params: ICellRendererParams<TaskStatusEnum>): boolean {
    this.setCellStatus(params.value);
    return true;
  }

  private setCellStatus(taskStatus: TaskStatusEnum): void {
    this.isSuccessful = taskStatus === TaskStatusEnum.Successful;
    this.isPending = [TaskStatusEnum.Created, TaskStatusEnum.Polling].includes(taskStatus);
    this.isFailed = [TaskStatusEnum.Failed, TaskStatusEnum.PollingTimeout].includes(taskStatus);
  }
}
